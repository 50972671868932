<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="工单详情" />
    <div class="gd-list">
      <div class="gd-item">
        <div class="header-item">
          <div class="class">{{ gdInfo.CnClassName }}</div>
          <div class="status" :class="gdInfo.Status == 1 ? 'status1' : ''">{{ gdInfo.Status == 1 ? '已完成' : '待处理' }}</div>
        </div>
        <div class="time">{{ $TOOL.dateFormat(gdInfo.AddTime * 1000) }}</div>
        <div class="desc">{{ gdInfo.Desc }}</div>
      </div>
      <div class="takl-item" v-for="(item, index) in gdInfo.Talk" :key="index">
        <div class="content">{{ item.Content }}</div>
        <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
      </div>
    </div>
    <div class="footer-item">
      <div class="content">
        <div class="input-item">
          <div class="input"><input v-model="Content" type="text" placeholder="请输入回复内容" /></div>
          <div class="btn" @click="handleSubmit">
            <van-loading v-if="submiting" color="#ffffff" />
            <span v-else>回复</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "GdDetail",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      id: '',
      Content: '',
      submiting: false,
      gdInfo: {}
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.handleGetGdDetail()
    }
  },
  methods: {
    async handleGetGdDetail() {
      const { id } = this
      var res = await this.$API.addTickets.post({
        Switch: 4,
        Id: id
      })
      if (res.error == 0) {
        res.info.Img = res.info && res.info.Img ? res.info.Img.split(',') : []
        this.gdInfo = res.info
      }
    },
    handleSubmit() {
      const { Content } = this
      if (!Content) {
        this.$toast('请输入回复内容')
        return
      }
      this.handleSendTalk(Content, 1)
    },
    async handleSendTalk(content, type) {
      const params = {
        Switch: 2,
        Content: content,
        Type: type,
        GdId: this.id
      }
      this.submiting = true
      var res = await this.$API.addTickets.post(params)
      this.submiting = false
      if (res.error == 0) {
        if (type == 1) {
          this.Content = ''
        }
        this.$toast.success(res.info)
        this.handleGetGdDetail()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.gd-list {
  padding: .24rem .32rem;
  .gd-item {
    display: block;
    margin-bottom: .24rem;
    padding: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    color: #242941;
    .header-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: .4rem;
      font-size: .28rem;
      font-weight: bold;
      .class {
        flex: 1;
      }
      .status {
        color: #ff2442;
        &.status1 {
          color: #276eff;
        }
      }
    }
    .time {
      margin-top: .2rem;
      color: #ccc;
      font-size: .24rem;
      line-height: .34rem;
    }
    .desc {
      margin-top: .24rem;
      font-size: .28rem;
      line-height: .4rem;
    }
  }
  .takl-item {
    padding: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    margin-bottom: 0.24rem;
    .content {
      line-height: .4rem;
      font-size: .28rem;
      font-weight: bold;
      color: #242941;
    }
    .time {
      margin-top: .12rem;
      font-size: .24rem;
      color: #ccc;
    }
  }
}
.footer-item {
  width: 100%;
  height: 1.84rem;
  .content {
    position: fixed;
    bottom: 0;
    width: var(--max-width);
    left: 50%;
    margin-left: var(---max-width-50);
    z-index: 10;
    padding: .24rem .32rem .8rem;
    height: 1.84rem;
    background-color: #fff;
    box-sizing: border-box;
    .input-item {
      width: 100%;
      height: .8rem;
      border-radius: .08rem;
      background-color: #eff2f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input {
        flex: 1;
        padding: 0 .32rem;
      }
      .btn {
        cursor: pointer;
        width: 1.6rem;
        height: .8rem;
        border-radius: .12rem;
        background: linear-gradient( 135deg, #58B7FF 0%, #276eff 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: .24rem;
      }
    }
  }
}
</style>